import React from 'react'

const Button = ({ bgColor, color, size, text, borderRadius }) => {
  return (
    <div>
      <button
        type="button"
        style={{ backgroundColor: bgColor, color, borderRadius }}
        className={`text-${size} p-3 hover:drop-shadow-xl transition duration-250 ease-in-out`}
      >
        {text}
      </button>
    </div>
  );
}

export default Button
