import React, { createContext, useContext, useState, useEffect } from "react";

const StateContext = createContext();


export const ContextProvider = ({ children }) => {
  const [activeMenu, setActiveMenu] = useState(true);
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState("#03C9D7");
  const [currentMode, setCurrentMode] = useState("Light");
  const [themeSettings, setThemeSettings] = useState(false);
  const [cartState, setCartState] = useState(false);
  const [chatState, setChatState] = useState(false);
  const [notificationState, setNotificationState] = useState(false);
  const [userProfileState, setUserProfileState] = useState(false);

  const setMode = (e) => {
    setCurrentMode(e.target.value);

    // saving the latest theme to local storage (or can be a database). So when the user comes back it will load to that theme
    localStorage.setItem("themeMode", e.target.value);
    setThemeSettings(false);
  };

  const setColor = (color) => {
    setCurrentColor(color);

    localStorage.setItem("colorMode", color);
    setThemeSettings(false);
  };

  return (
    <StateContext.Provider
      value={{
        activeMenu,
        setActiveMenu,
        screenSize,
        setScreenSize,
        currentColor,
        currentMode,
        themeSettings,
        setThemeSettings,
        setColor,
        setMode,
        cartState,
        setCartState,
        chatState,
        setChatState,
        notificationState,
        setNotificationState,
        userProfileState,
        setUserProfileState,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
