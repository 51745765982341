import React, { useState } from "react";
import { ColorPickerComponent } from "@syncfusion/ej2-react-inputs";
import { TbClipboard } from "react-icons/tb";

import { Header } from "../components";

const ColorPicker = () => {

  const [HexColor, setHexColor] = useState("#008000"); // Set the default value here

  const change = (args) => {
    const newHexColor = args.currentValue.hex;
    setHexColor(newHexColor);

    document.getElementById("preview").style.backgroundColor = newHexColor;
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(HexColor);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="App" title="Color Picker" />

      <div className="text-center">
        <div id="preview" />

        <div className="flex justify-center items-center gap-1 flex-wrap">
          <p
            id="hex-code"
            className="text-xl text-gray-800 mt-4 mb-4 font-semibold"
          >{`Hex: ${HexColor}`}</p>
          <button
            type="button"
            className="p-1 text-xl opacity-0.9 hover:drop-shadow-xl transition duration-250 ease-in-out"
            onClick={copyToClipboard}
          >
            <TbClipboard />
          </button>
        </div>

        <div className="flex justify-center items-center gap-20 flex-wrap">
          <div>
            <p className="text-2xl font-semibold mt-2 mb-4">Inline Palette</p>
            <ColorPickerComponent
              id="inline-palette"
              mode="Palette"
              modeSwitcher={false}
              inline
              showButtons={false}
              change={change}
            />
          </div>

          <div>
            <p className="text-2xl font-semibold mt-2 mb-4">Inline Picker</p>
            <ColorPickerComponent
              id="inline-palette"
              mode="Picker"
              modeSwitcher={false}
              inline
              showButtons={false}
              change={change}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorPicker;
